<template>
  <div>
    <b-row>
      <b-col md="12">
        <export-button :filter="exportFilter"
                       url="/user-session/export-report-session"
                       :disabled="loading.overlay || loading.input || !data.items.length"
                       file-title="Player_session_Report.xlsx"/>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{ $t("player_session_report") }}</b-card-title>

          <b-row>
            <b-col md="3">
              <b-form-group
                  :label="this.$t('labels.datefrom')"
                  label-for="datefrom"
                  rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                    v-model="dateFrom"
                    v-if="userSelected !== null"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd-m-Y H:i',
                      enableTime: true,
                      time_24hr: true,
                      wrap : true,
                      plugins: [
                         new yearDropdownPlugin({
                           yearStart: 100,
                           yearEnd: 0
                        })
                       ],
                      }"
                />

                <v-select v-else disabled/>

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                    v-model="dateTo"
                    class="form-control"
                    v-if="userSelected !== null && dateFrom"
                    :config="{
                      altInput: true,
                      altFormat: 'd-m-Y H:i',
                      enableTime: true,
                      time_24hr: true,
                      wrap : true,
                      plugins: [
                         new yearDropdownPlugin({
                           yearStart: 100,
                           yearEnd: 0
                        })
                       ],
                    }"
                />

                <v-select v-else disabled/>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Player Name" label-for="username">
                <template #label>{{ $t("labels.name_player") }}</template>
                <v-select
                    @option:selected="selectUser"
                    :options="users"
                    @search="onSearch"
                    :filterable="false"
                    :clearable="false"
                    :loading="loading.input"
                    label="userName"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.userName }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-overlay class="mt-2" :show="loading.overlay">

            <b-table-simple style="min-height: 300px"
                            hover
                            caption-top
                            responsive>
              <b-thead head-variant="light">
                <b-tr>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('session_id')">
                    {{ this.$t('session_id') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('player_id')">
                     {{ this.$t('player_id') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('init_date_session')">
                    {{ this.$t('init_date') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('end_date_session')">
                    {{ this.$t('end_date') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        title="IP">
                    IP
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('geolocation')">
                    {{ this.$t('geolocation') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('amount_bet_session')">
                    {{ this.$t('bet') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('amount_won_session')">
                    {{ this.$t('won') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('deposited_funds_session')">
                    {{ this.$t('deposited_funds') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('end_reason_session')">
                    {{ this.$t('end_reason') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('start_balance_session')">
                    {{ this.$t('start_balance') }}
                  </b-th>

                  <b-th v-b-tooltip.hover
                        :title="this.$t('end_balance_session')">
                    {{ this.$t('end_balance') }}
                  </b-th>


                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in data.items"
                    :key="indexTr"
                >
                  <b-td class="text-noWrap">
                    {{ tr.sessionId }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr._id }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ formatDate(tr.startSession) }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ formatDate(tr.endSession) }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.ipAddress }}
                  </b-td>

                  <b-td class="text-noWrap">
                    <ip-parse :ip='tr.ipAddress' :show="['country']"/>
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.amountBet | currency({symbol: ""}) }} {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.amountWin | currency({symbol: ""}) }} {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.amountDeposit | currency({symbol: ""}) }} {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.endingSessionReason }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.startBalance | currency({symbol: ""}) }} {{ currency }}
                  </b-td>

                  <b-td class="text-noWrap">
                    {{ tr.endBalance | currency({symbol: ""}) }} {{ currency }}
                  </b-td>

                </b-tr>
                <b-tr v-if='data.items.length < 1'>
                  <b-th colspan="16" class='text-center'>
                    <div class='m-2'>{{ this.$t('no_data') }}</div>
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>

          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BCollapse,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BRow,
  BTable,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
} from "bootstrap-vue";
import store from "@/store";
import {onUnmounted} from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import {VSelectTree, VTree} from "vue-tree-halower";
import userStoreModule from "../../../apps/user/userStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import IpParse from '@/views/components/ipParse/IpParse.vue'
import { axiosErrorHandle } from '@core/utils/errorHandler'
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import {translatableText} from "@core/utils/utils";

export default {
  components: {
    ExportButton,
    IpParse,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
  },
  name: "PlayerSessionReport",

  setup() {
    const APP_USER_PATH = 'app-user'

    if (!store.hasModule(APP_USER_PATH))
      store.registerModule(APP_USER_PATH, userStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_USER_PATH)) store.unregisterModule('app-user')
    })
  },

  data() {
    return {
      users: [],
      userSelected: null,
      loading: {
        overlay: false,
        input: false
      },
      data: {
        total: 0,
        items: []
      },

      dateFrom: '',
      dateTo: '',
    };
  },

  watch: {
    dateFrom: {
      handler() {
        if (this.dateFrom !== '' && this.dateTo === '') {
          this.endDate = new Date()
        }

        if (this.dateFrom === '') {
          this.dateTo = ''
        }

        this.getData()
      }
    },

    dateTo: {
      handler() {
        this.getData()
      }
    },
  },

  computed: {
    ...mapState("whitelabelCurrencyNabvar", ["whitelabel", "currency"]),
    exportFilter(){
      return {
        userName: this.userSelected?.userName,
        currency: this.currency,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }
    }
  },

  methods: {
    yearDropdownPlugin,
    ...mapActions("player", ["getUserSessions"]),


    getData() {
      this.setLoadingOverlay(true)

      const filter = {
        userName: this.userSelected.userName,
        currency: this.currency,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }

      this.getUserSessions(filter).then(r => {
        this.data = r.data

      })
        .catch(async (error)=>{
          this.data.items = [] // <--reset data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "AlertTriangleIcon",
              text: await translatableText({text: axiosErrorHandle(error)}),
              variant: "danger",
            },
          });
        })
        .finally(() => {
        this.setLoadingOverlay(false)
      })

    },

    setLoadingOverlay(flag = false) {
      this.loading.overlay = flag
    },

    setLoadingInput(flag = false) {
      this.loading.input = flag
    },

    selectUser(user) {
      this.userSelected = user;
      this.getData()
    },

    onSearch(username, loading) {
      if (username.length) {
        this.setLoadingInput(true)

        const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = { username: username.trim(), whitelabel: whitelabelId };

        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.search(loading, queryParams, this);
        }, 500);
      }
    },

    search(loading, queryParams, vm) {
      store.dispatch("app-user/fetchUsersByName", queryParams).then((response) => {
        this.users = response.data;
        this.setLoadingInput(false)
      }).catch(() => {
        this.setLoadingInput(false)
      });
    },

    formatDate(value) {
      return value ? moment(value).format('DD/MM/YYYY hh:mm a') : '-'
    },

  },

};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
